* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.podborki {
  margin: -70px 0 0;
  padding: 100px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.podborki__blocks {
  margin: 10px 0 30px;
  width: 100%;
  height: 500px;
  position: relative;
}

.podborki__block {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 500px;
  margin: 0 10px;
}

.podborka-image {
  height: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  object-position: center;
}

.podborki__block0 {
  background-image: url(..//../images/podborki/podborki1.jpg);
}

.podborki__block1 {
  background-image: url(..//../images/podborki/podborki2.jpg);
}

.podborki__block2 {
  background-image: url(..//../images/podborki/podborki3.jpg);
}

.podborki__block3 {
  background-image: url(..//../images/podborki/podborki4.jpg);
}

.podborki__block4 {
  background-image: url(..//../images/podborki/podborki5.jpg);
}

.podborki__block5 {
  background-image: url(..//../images/podborki/podborki6.jpg);
}

.podborki__content {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  top: 75%;
  height: 25%;
  padding: 3%;
  /*background-color: rgba(233, 235, 238, 0.7);*/
  background-color: rgba(0,0,0,.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 0px;
  cursor: pointer;
  transition: color 0.4s;
}

.podborki__content:hover {
  background-color: #09c6ab;
}

.podborki__info-date {
  font-weight: normal;
  font-size: 18px;
  margin: 0;
  position: relative;
  z-index: 1;
}

.podborki__content p,
.podborki__content h3 {
  color: #fff;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.podborki__content p {
  align-self: center;
  text-align: center;
  font-weight: 600;
  justify-self: center;
}

.podborki__info-date {
  text-align: center;
  font-weight: 600;
  justify-self: center;
}

.podborki__info-date {
  text-align: start;
  max-width: 100%;
}

.podborki__content * {
  cursor: pointer;
}

.podborki__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 10%;
  width: 100%;
  background: linear-gradient(-45deg, #09c6ab, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.podborki__info p {
  text-align: center;
  font-weight: 700;
  color: #fff;
}

.podborki__button {
  display: inline-block;
  border: 2px solid #09c6ab;
  width: 250px;
  line-height: 3.5em;
  margin: 0 auto;
  text-align: center;
  transition: transform 0.2s;
  cursor: pointer;
}

.podborki__button:hover {
  transform: scale(1.1);
}

.podborki__info-price {
  font-weight: 400;
  font-size: 24px;
  margin: 0 10px 0 10px;
}

.info-price {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ee4957;
  border-radius: 10px;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  padding: 5px 15px;
}

.info-date {
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: #3997f0;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 15px;
}

.podborki__content1 h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 1500px) {
  .podborki__info-price {
    font-size: 16px;
  }
  .info-date {
    font-size: 16px;
  }
  .podborki__info-date {
    font-size: 16px;
  }
  .info-price {
    font-size: 20px;
  }
  .podborki__content1 h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .podborki__blocks {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 400px);
  }
}

@media screen and (max-width: 700px) {
  .podborki__blocks {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 400px);
  }
  .podborki__info-date {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .podborki__blocks {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 400px);
  }
  .podborki h1 {
    text-align: center;
    font-size: 20px;
  }
  .modal-block-h1 {
    font-size: 30px;
  }
  .podborki__title {
    font-size: 30px;
  }
  /*.podborki__content {
    margin: 0 20px;
    top: 72%;
    height: 23%;
  }*/
}

.modal-block-content {
  padding: 0;
  max-width: 750px;
  max-height: 90vh;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 209px;
  z-index: 11;
  transition: all 1s;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 26px 0 rgba(0,0,0,.22), 0 20px 28px 0 rgba(0,0,0,.30);
}

.modal-block-h1 {
  color: #000;
  font-size: 20px;
  padding: 20px;
  margin: 0;
  background-color: #09c6ab;
}

.overlay__block {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 0;
}

.modal-block-p {
  font-size: 20px;
  margin: 0;
  color: #000;
}

.modal-block-item,
.modal-block-li {
  list-style: none;
  color: #000;
}

.modal-block-links h3 {
  font-weight: 600;
  font-size: 20px;
}

.modal-block-info .btn {
  margin-top: 20px;
  align-self: flex-start;
}

.modal-block-info p,
.modal-block-li  {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-top: 10px;
}

.modal-block-p-small,
.modal-block-li {
  color: #000;
  font-weight: normal !important;
}


.modal-podborki-block-img p {
  padding-left: 20px;
}

.modal-block-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-block-content p,
.modal-block-content h1,
.modal-block-content ul,
.modal-block-content a,
.modal-block-content button {
  position: relative;
  z-index: 10;
}

.btn-podborki {
  height: 50px;
  margin: 0 auto 10px !important;
  padding: 0 15px !important;
  width: max-content;
  display: flex;
  justify-content: center;
  align-self: center;
  background: #59267c !important;
  background-size: 400% 400%;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 111;
}

.btn-podborki * {
  cursor: pointer;
}

.btn-podborki a {
  display: inline-block;
  align-self: center;
  color: #fff;
  line-height: 50px;
  position: relative;
  top: -5px;
}

.btn-podborki:hover {
  transform: scale(1.1)
}

.btn-podborki a:hover {
  color: #fff;
}
/* background: linear-gradient(120deg, #59267c, #8f5db7, #8a83b3, #e2d4e7, #81cd50) !important;
  background-size: 400% 400%; */

.podborki__info-text {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.podborki__info .fab {
  margin-right: 20px;
  font-size: 24px;
  line-height: 50px;
}

@media screen and (max-width: 700px) {
  .podborki__block {
    margin: 0;
  }
  .modal-block-p {
    font-size: 20px;
  }
}

@media screen and (max-width: 350px) {
  .modal-block-p,
  .modal-block-h1 {
    font-size: 14px;
    margin: 0;
  }
  .modal-block-li {
    font-family: 12px;
  }
}

@media screen and (max-width: 500px) {
  .podborki__title {
    font-size: 30px;
  }
}

.modal-podborki-container {
  display: flex;
  border: 1px solid #dadce0;
  border-radius: 10px;
  margin: 0 30px 30px;
  padding: 24px 0;
}

.modal-podborki-container img {
  height: 300px;
  width: 350px;
  padding: 0 24px;
  margin: 20px 0;
  object-fit: cover;
}

.modal-podborki-container1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 450px;
  margin: auto;
}
  
.podborki-date {
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

.modal-block-info {
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 20px;
  height: 100%;
}

.modal-podborki-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-block-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  padding: 30px 30px 30px;
}

.modal-block-links h3 {
  margin-bottom: 0;
  margin-right: 10px;
}

.fa-times {
  color: #000;
  cursor: pointer;
  margin-left: 20px;
}

.fa-phone-alt {
  margin-right: 10px;
}

.modal-block-info div {
  overflow-y: auto;
  max-height: 400px;
}


@media screen and (max-width: 700px) {
  .modal-podborki-container {
    flex-direction: column;
    border: 1px solid #dadce0;
    border-radius: 10px;
    margin: 0 10px 30px;
    padding: 0 0 20px;
  }

  .modal-block-content {
    max-height: max-content;
  }

  .modal-podborki-container {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .modal-block-info div {
    overflow-y: hidden;
    max-height: max-content;
  }

  .podborki-date {
    font-size: 18px;
  }
  
  .modal-podborki-container img {
    padding: 0;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 0;
  }
  
  .modal-block-info {
    max-height: 100%;
    margin: auto;
    width: 100%;
    padding: 0 20px;
  }
  .modal-block-info p {
    font-size: 18px;
  }
  .modal-block-content {
    top: 180px;
  }
  .modal-block-links {
    padding: 15px;
    flex-direction: column;
  }
  .modal-block-links h3 {
    margin-bottom: 15px;
    margin-right: 0;
    text-align: start;
    align-self: start;
    margin-top: 15px;
    font-size: 18px;
  }
  .modal-block-links .fa-times {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .modal-block-links a {
    align-self: start;
  }
  .date-margin {
    margin-top: 20px;
  }
  .modal-block-li,
  .modal-block-p-small {
    font-size: 16px !important;
  }
}

