* {
  box-sizing: border-box;
}

.form {
  margin: 0;
  padding: 5% 20%;
  background: linear-gradient(-45deg, #09c6ab, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.form p {
  color: #fff;
  margin: 0 50px 0 0;
  text-align: start;
  max-width: 65%;
}

.form__button {
  display: inline-block;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #09c6ab;
  color: #fff;
  width: 250px;
  line-height: 3.5em;
  margin: 0;
  text-align: center;
  transition: transform 0.2s;
  cursor: pointer;
}
  
.form__button:hover {
  transform: scale(1.1);
}

.form span {
  font-weight: 900;
  font-size: 24px;
}

@media screen and (max-width: 1300px) {
  .form {
    padding: 5% 10%;
  }
}

@media screen and (max-width: 1000px) {
  .form {
    padding: 5% 10%;
    flex-direction: column;
  }
  .form span {
    font-weight: 900;
    font-size: 18px;
  }
  .form p {
    max-width: 500px;
    margin: 0 0 20px;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .form {
    padding: 5%;
    flex-direction: column;
    justify-content: center;
  }

}
