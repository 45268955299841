* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.podborka-main-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.podborka-main-photo {
  width: 100%;
  height: 100vh;
  position: absolute;
  object-fit: cover;
}

.podborka-page_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.podborka-main-page h1 {
  color: #fff;
  border-color: #fff;
  font-size: 60px;
  letter-spacing: 2px;
  max-width: 600px;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

.podborka-page__info {
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.podborka-page__info-block {
  padding: 30px;
  border-right: 1px solid #e1e1e1;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.podborka-page__info-block:first-child {
  border-left: 1px solid #e1e1e1;
}

.podborka-page__info-block p {
  clear: both;
  float: none;
  width: 100%;
  margin-left: 0;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #999;
  text-align: center;
}

.podborka-page__info-block h3 {
  color: #000;
  font-weight: 700;
  font-size: 20px;
}

.podborka-page__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 40px 0;
  position: relative;
  z-index: 100;
  background-color: #fff;
}

.podborka-page__content h3 {
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.podborka-page__content p,
.podborka-page__content li {
  font-size: 16px;
  text-align: start;
  color: #000;
}

.podborka-page__btn {
  background-color: #09c6ab;
  border: none;
  outline: none;
  border-radius: 10px;
  width: max-content;
  padding: 20px;
  color: #fff;
}

.podborka-page__btn:hover {
  color: #fff;
  background-color: #0be2c2;
}

@media screen and (max-width: 767px) {
  .podborka-main-page h1 {
    font-size: 24px;
  }
  .podborka-page__content h3 {
    font-size: 20px;
  }
  .podborka-page__content p,
  .podborka-page__content li {
    font-size: 14px;
  }
  .podborka-page__info-block h3 {
    font-size: 16px;
  }
  .podborka-page__info-block {
    padding: 30px 10px;
  }
  .podborka-page__info-block p,
  .podborka-page__info-block h3,
  .podborka-page__info-block a {
    text-align: center;
  }
  .podborka-page__info-block {
    width: 50%;
    border-bottom: 1px solid #e1e1e1;
  }
  .podborka-page__info-block:last-child {
    width: 100%;
  }
  .podborka-page__info {
    flex-wrap: wrap;
  }
  .podborka-page__btn {
    width: 80%;
    padding: 10px 20px;
    color: #fff;
  } 
  .podborka-page__info-block-news {
    padding: 30px 0 0;
    width: 100%;
  }
}

.content-info {
  white-space: pre-wrap;
}

.content-info-news {
  width: 100%;
  text-align: start;
}

.content-info a {
  word-wrap: break-word;
}

.content-info a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1100px) {
  .podborka-page__content-news {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.content-info {
  white-space: pre-wrap;
}
