body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.module {
  position: static;
  width: auto;
  background-color: #09c6ab;
  padding: 40px 15% 20px;
}

.new_f-ext-btn-container {
  cursor: pointer;
}

.new_f-wrapper-bg-imgs,
.new_f-container {
  background: transparent !important;
}

.new_f-container {
  margin-bottom: 0;
}

.new_f-dropdown-btn .cnt::after, .new_f-dropdown-btn::after {
  border-color: #0adfbf transparent transparent !important;
}

.new_f-search-dates-wrap svg {
  fill: #0adfbf;
}

.new_f-people-item.active {
  background-color: #0adfbf;
}

@media screen and (max-width: 1200px) {
  .module {
    position: static;
    width: auto;
    background-color: #09c6ab;
    padding: 60px 20px 20px;
  }
  .new_f-container {
    margin-bottom: 0;
  }
  .new_f-form .new_f-form-submit {
    background-color: #f7941d;
  }
}

new_os {
  z-index: 10000000000 !important;
}

small-form {
  z-index: 10000000000 !important;
}

.new_r-wrapper {
  margin: 0;
}

.new_r-currency-switch {
  margin-right: 5%;
}
.new_t-menu-back {
  margin-top: 20px;
}
.new_r-filters-title,
.new_t-header-geo.new_t-geo-with-map {
  color: #f7941d;
}
.new_t-header-geo svg {
  fill: #f7941d;
}
.new_t-menu-item.selected {
  background-color: #f7941d;
}
.new_t-menu-item {
  border-bottom: 1px solid #f7941d;
}
.new_t-menu-item.selected::after {
  border-color: #f7941d transparent transparent transparent;
}
.new_t-menu-back-link,
.new_t-header-title {
  color: white;
}
.new_t-menu-item {
  color: #f7941d;
  border-bottom: 1px solid #f7941d;
}
.new_t-header-geo.new_t-geo-with-map::after {
  background: #f7941d;
}
.new_t-order-price,
.new_r-item-icon {
  color: #0be2c2;
}
.new_r-item-icon {
  fill: #0be2c2;
}
.new_t-tab-content-top, .new_t-modal-body-top, .new_f-dropdown-body-top {
  background: #09c6ab;
}

.new_t-important,
.new_r-item .new_r-item-geo {
  color: #0be2c2 !important;
}
.new_r-filters-reset {
  color: white;
}
.new_r-filters-title {
  color: white;
}

.new_r-panel {
  margin-top: 20px;
  margin-bottom: 0;
}

@media (max-width: 478px) {
  .new_f-dropdown-body-date .new_f-dropdown-body-top {
    background: #09c6ab;
  }
  .new_layer-open-to #new_os-to .new_f-dropdown-btn {
    margin-top: 60px;
  }
  .new_results-map-overlay, .new_f-ext-container, .new_modal-open .new_tour-one-col-sm .new_t-modal-body, .new_t-tab-content.map, .new_t-tab-content.reviews, .new_f-dropdown-container:not(#new_os-date-check) .new_f-dropdown-body, .new_layer-open .new_t-change-people .new_f-dropdown-body {
    margin-top: 60px !important;
  }
}

.new_t-menu-wrap::after {
  background: #f7941d;
}

.b24-widget-button-wrapper {
  z-index: 10000000000000000000 !important;
}

body .fancybox-opened {
  z-index: 10000000000 !important;
}

body .fancybox-overlay {
  z-index: 100000000000 !important;
}