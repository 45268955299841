* {
  box-sizing: border-box;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal {
  max-width: 500px;
  padding: 15px;
  max-height: 500px;
  background-color: rgb(230, 215, 215);
  border: none;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 1s;
  border-radius: 50px;
  position: absolute;
  top: 50px;
}

.modal h1 {
  display: inline;
  align-self: center;
  margin: 5px 0;
  text-align: center;
}

.modal__btn {
  width: max-content;
  padding: 10px;
  line-height: 5px;
  height: 30px;
  border: none;
  outline: none;
  background-color: rgb(175, 115, 126);
  color: #fff;
  cursor: pointer;
  align-self: center;
  border-radius: 10px;
}

.modal p {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 450px;
  height: auto;
}

.visible {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 500px) {
  .modal {
    width: 300px;
  }
}