.media {
  width: 100%;
  background-image: url('../../images/beach.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.media__block {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 5%;
  border-radius: 30px;
  margin: 0 30px;
}

.media__block h3 {
  color: #09c6ab;
}

.media__block * {
  text-align: center;
  max-width: 700px;
}

.media__link-block {
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #09c6ab;
  margin-left: 20px;
  transition: transform 0.2s;
  cursor: pointer;
}

.cursor {
  cursor: pointer !important;
}

.media__link-block:hover {
  transform: scale(1.1);
}

.fab {
  color: #fff;
  font-size: 35px;
  line-height: 70px;
}

@media screen and (max-width: 600px) {
  .media {
    height: 500px;
  }
  .media__link-block {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
  
  .fab {
    font-size: 20px;
    line-height: 40px;
  }
  .media__block p {
    font-size: 14px;
  }
  .media__block h3 {
    font-size: 18px;
  }
  .media__link-block:first-of-type {
    margin-left: 0;
  }
}
