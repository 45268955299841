a {
  cursor: pointer;
}

.footer__ocean {
  height: 300px;
  position: relative;
}
.footer__ocean {
  background: transparent;
  overflow: hidden;
}

.ocean { 
  height: 20%;
  width: 100%;
  position: absolute;
  bottom:0;
  left:0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
  position: absolute;
  top: 198px;
  width: 6400px;
  height: 250px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -170px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}

.footer__block {
  position: absolute;
  top: -300px;
  left: 0;
  z-index: 1000;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__list {
  list-style: none;
  padding: 0;
}

.footer__block a {
  font-size: 18px;
  color: #09c6ab;
  margin-right: 10px;
}

.color {
  color: #fff;
}

.footer__list-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 150px;
  margin-left: 30px;
}

.footer__list-contact:last-child {
  margin-right: 0;
}

.footer__block h3 {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .footer__list-contact {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.copyright {
  position: relative;
  bottom: -7px;
  padding: 0 20px;
  text-align: center;
}


.pull-right .fab {
  color: #09c6ab;
}

.footer__lists {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .footer__lists {
    flex-direction: column;
    justify-content: space-between;
  }
  .footer__list-contact {
    margin-bottom: 0;
    margin-left: 0;
  }
  .footer__list-contact:last-child {
    margin-bottom: 30px;
  }
}

.copyright-block {
  position: absolute;
  top: -50px;
  text-align: center;
  z-index: 1000;
  display: block;
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .copyright-block {
    top: -80px;
  }
}

.copyright-icons .fab{
  color: #09c6ab;
}

.copyright {
  margin: 0;
}

.copyright-icons {
  display: block;
  margin-bottom: 100px !important;
}

.arrow-up {
  position: fixed;
  bottom: 30px;
  left: 20px;
  width: 50px;
  height: 50px;
  background-color: #09c6ab;
  border-radius: 50%;
  z-index: 1000;
}

.arrow-up .fas {
  color: #fff;
  position: absolute;
  line-height: 50px;
  font-size: 30px;
  z-index: 1001;
  left: 25%;
}
