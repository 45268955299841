.about {
  margin-top: -70px;
  padding: 100px 5% 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.about h4 {
  color: #777;
}

.about__block {
  align-self: center;
}

.about__text {
  margin-bottom: 20px;
  font-size: 14px;
  margin-right: 50px;
  border: 1px solid #09c6ab;
  padding: 40px 40px 0px;
  border-radius: 10px;
  background-color: rgba(9, 198, 170, 0.1);
}

@media screen and (max-width: 1100px) {
  .about {
    flex-direction: column;
    justify-content: space-between;
  }
  .about__text {
    font-size: 14px;
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .about__text {
    font-size: 10px;
    padding: 40px 40px 0px;
    border: none;
    border-radius: 0;
  }
  .about {
    padding: 100px 0 20px;
  }
}
