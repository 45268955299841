* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.news-page__page {
  width: 100%;
  padding: 90px 20px 30px;
  background-color: #e9ebee;
}

.news-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.news-page__title {
  text-align: center;
  align-self: center;
}

.news-page-menu {
  background-color: #fff;
  width: 50%;
  height: 300px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-right: 10px;
  padding: 20px;
  position: sticky;
  top: 100px;
  left: 0;
}

.news-page__blocks {
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
}

.news-page__block {
  background-color: #fff;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  width: 500px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-right: 20px;
  height: min-content;
}

.news-page__block * {
  cursor: pointer;
}

.news-page__img {
  width: 100%;
  height: 200px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  top: 0;
  justify-content: space-between;
}

.news-page__photo {
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.news-page__overlay {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.news-page__content {
  max-width: 100%;
}

.news-page h2 {
  align-self: flex-end;
  font-size: 22px;
  color: #fff;
  width: 60%;
  position: relative;
  z-index: 1;
  padding: 0 0 0 20px;
}

.news-page__text {
  white-space: pre-wrap;
  height: calc(24px * 10);
}

.news-page p {
  padding: 20px 20px 0;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
}

.full-text {
  min-height: 240px !important;
  height: max-content !important;
}

.menu-button {
  display: none;
}

.read-more {
  display: block;
  position: relative;
  z-index: 1000;
  margin-top: -5px;
  margin-bottom: 20px;
  padding-left: 20px;
  border: none;
  outline: none;
  align-self: flex-end;
  background-color: transparent;
  color: #09c6ab;
}

@media screen and (max-width: 1600px) {
  .news-page__block {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .news-page__block {
    width: 300px;
  }
}

@media screen and (max-width: 600px) {
  .news-page__block {
    margin-right: 0;
  }
  .news-page {
    flex-direction: column;
  }
  .news-page__page {
    padding: 90px 0 30px;
  }
  .menu-button {
    display: block;
    position: relative;
    z-index: 1000;
    margin: 0 20px 20px;
    border: none;
    outline: none;
    align-self: flex-end;
    background-color: transparent;
    color: #868282;
  }
  
  .news-page-menu {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    padding: 30px 20px 20px;
  }
  .news-page-menu__active {
    visibility: visible;
    opacity: 1;
  }
  .news-page__blocks {
    width: 100%;
  }
}

.news-page__banner {
  background-image: url(..//..//images/beach.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin-top: 70px;
  position: relative;
}

.news-page__overlay,
.news-page__banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.news-page__banner-content {
  max-width: 60%;
  padding: 100px 10% 0;
}

.news-page__banner-content h2,
.news-page__banner-content p {
  color: #fff;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1300px) {
  .news-page__banner-content {
    max-width: 70%;
    padding: 80px 10% 0;
  }
}

.news-page__block a {
  font-size: 14px;
  word-wrap: break-word;
}


@media screen and (max-width: 900px) {
  .news-page__banner-content {
    max-width: 80%;
    padding: 60px 10% 0;
  }
}

@media screen and (max-width: 700px) {
  .news-page__banner {
    height: 500px;
  }
  .news-page__banner-content {
    max-width: 100%;
    padding: 50px 10% 0;
  }
  .news-page__banner-content h2,
  .news-page__banner-content p {
    font-size: 18px;
  }
  .news-page h2 {
    font-size: 18px;
    width: 70%;
  }
}

.news-page__form {
  display: flex;
  width: 100%;
}

.news-page__search {
  border: 1px solid #09c6ab;
  width: 70%;
  position: relative;
}

.news-page__search-btn {
  width: 30%;
  background-color: #09c6ab;
  border: none;
  outline: none;
  padding: 5px;
  color: #fff;
  transition: transform 0.2s;
}

.news-page__search-btn:hover {
  transform: scale(1.1);
}

.footer__ocean {
  background: #fff;
  overflow: hidden;
}

.news-page__img p {
  color: #fff;
  align-self: flex-end;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.margin-btn {
  margin-top: 20px;
}

