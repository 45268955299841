* {
  box-sizing: border-box;
}

.container-map {
  padding: 100px 10% 0;
  background-color: #e9ebee;
  min-height: 100vh;
}

.container-map svg {
  stroke: #fff;
}

.container-map path {
  cursor: pointer;
  fill: #094940;
  outline: none;
}

.container-map path:hover,
.container-map path:active,
.container-map path:focus {
  fill: #09c6ab;
} 
