.block-team {
  margin: -70px 0 50px;
  padding: 100px 25% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employee-blocks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.employee__photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: 20px 0;
  object-position: top;
}

.employee h3,
.employee p {
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 1500px) {
  .block-team {
    padding: 100px 15% 0;
  }
}

@media screen and (max-width: 1000px) {
  .employee-blocks {
    flex-wrap: wrap;
    justify-content: center;
  }
  .employee {
    margin: 0 10px;
  }
}

@media screen and (min-width: 1200px) {
  .employee {
    margin: 0 100px;
  }
  .employee-blocks {
    justify-content: center;
  }
}

.mobile-team {
  display: none;
}

@media screen and (max-width: 1100px) {
  .desktop-team {
    display: none;
  }
  .mobile-team {
    display: flex;
  }
}