.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
  background-color: #09c6ab;
}

.preloader__title {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.preloader__title h2 {
  color: #fff;
  align-self: center;
  margin-top: 30px;
  font-family: sans-serif;
  animation: preloader-animation 2s infinite;
}

.loader_img {
  width: auto;
  height: 30vh;
  object-fit: contain;
  animation: preloader-animation 2s infinite;
}

@keyframes preloader-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
