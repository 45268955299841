*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    cursor: default;
}


.testimonials-container {
  width: 100%;
  height: 500px;
  font-size: 16px;
  font-family: inherit;
  background: #1096ad;
  position: relative;
}

.testim {
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  -moz-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  -o-transform: translatey(-50%);
  transform: translatey(-50%);
}

.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  padding: 40px 20px;
  margin: auto;
}

.testim .arrow {
  display: block;
  position: absolute;
  color: #eee;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .3s ease-in-out;    
  -ms-transition: all .3s ease-in-out;    
  -moz-transition: all .3s ease-in-out;    
  -o-transition: all .3s ease-in-out;    
  transition: all .3s ease-in-out;
  padding: 5px;
  z-index: 22222222;
}

.testim .arrow:before {
  cursor: pointer;
}

.testim .arrow:hover {
  color: #09c6ab;
}
  

.testim .arrow.left {
  left: 10px;
}

.testim .arrow.right {
  right: 10px;
}

.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  display: block;
  z-index: 3333;
  height: 12px;
}

.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all .5s ease-in-out;    
  -ms-transition: all .5s ease-in-out;    
  -moz-transition: all .5s ease-in-out;    
  -o-transition: all .5s ease-in-out;    
  transition: all .5s ease-in-out;
  position: relative;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #09c6ab;
  border-color: #09c6ab;
}

.testim .dots .dot.active {
  -webkit-animation: testim-scale .5s ease-in-out forwards;   
  -moz-animation: testim-scale .5s ease-in-out forwards;   
  -ms-animation: testim-scale .5s ease-in-out forwards;   
  -o-animation: testim-scale .5s ease-in-out forwards;   
  animation: testim-scale .5s ease-in-out forwards;   
}
  
.testim .cont {
  position: relative;
  overflow: hidden;
}

.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 70px 0;
  opacity: 0;
}

.testim .cont > div.inactive {
  opacity: 1;
}
  

.testim .cont > div.active {
  position: relative;
  opacity: 1;
}
  

.testim .cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}

.testim .cont div h2 {
  color: #fff;
  font-size: 1.3em;
  margin: 15px 0;
}

.testim .cont div p {
  font-size: 1.15em;
  color: #eee;
  width: 80%;
  margin: auto;
}

.testim .cont div.active .img img {
  -webkit-animation: testim-show .5s ease-in-out forwards;            
  -moz-animation: testim-show .5s ease-in-out forwards;            
  -ms-animation: testim-show .5s ease-in-out forwards;            
  -o-animation: testim-show .5s ease-in-out forwards;            
  animation: testim-show .5s ease-in-out forwards;            
}

.testim .cont div.active h2 {
  -webkit-animation: testim-content-in .4s ease-in-out forwards;    
  -moz-animation: testim-content-in .4s ease-in-out forwards;    
  -ms-animation: testim-content-in .4s ease-in-out forwards;    
  -o-animation: testim-content-in .4s ease-in-out forwards;    
  animation: testim-content-in .4s ease-in-out forwards;    
}

.testim .cont div.active p {
  -webkit-animation: testim-content-in .5s ease-in-out forwards;    
  -moz-animation: testim-content-in .5s ease-in-out forwards;    
  -ms-animation: testim-content-in .5s ease-in-out forwards;    
  -o-animation: testim-content-in .5s ease-in-out forwards;    
  animation: testim-content-in .5s ease-in-out forwards;    
}

.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide .5s ease-in-out forwards;            
  -moz-animation: testim-hide .5s ease-in-out forwards;            
  -ms-animation: testim-hide .5s ease-in-out forwards;            
  -o-animation: testim-hide .5s ease-in-out forwards;            
  animation: testim-hide .5s ease-in-out forwards;            
}

.testim .cont div.inactive h2 {
  -webkit-animation: testim-content-out .4s ease-in-out forwards;        
  -moz-animation: testim-content-out .4s ease-in-out forwards;        
  -ms-animation: testim-content-out .4s ease-in-out forwards;        
  -o-animation: testim-content-out .4s ease-in-out forwards;        
  animation: testim-content-out .4s ease-in-out forwards;        
}

.testim .cont div.inactive p {
  -webkit-animation: testim-content-out .5s ease-in-out forwards;    
  -moz-animation: testim-content-out .5s ease-in-out forwards;    
  -ms-animation: testim-content-out .5s ease-in-out forwards;    
  -o-animation: testim-content-out .5s ease-in-out forwards;    
  animation: testim-content-out .5s ease-in-out forwards;    
}

@-webkit-keyframes testim-scale {
  0% {
      -webkit-box-shadow: 0px 0px 0px 0px #eee;
      box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
      -webkit-box-shadow: 0px 0px 10px 5px #eee;        
      box-shadow: 0px 0px 10px 5px #eee;        
  }

  70% {
      -webkit-box-shadow: 0px 0px 10px 5px #09c6ab;        
      box-shadow: 0px 0px 10px 5px #09c6ab;        
  }

  100% {
      -webkit-box-shadow: 0px 0px 0px 0px #09c6ab;        
      box-shadow: 0px 0px 0px 0px #09c6ab;        
  }
}

@-moz-keyframes testim-scale {
  0% {
      -moz-box-shadow: 0px 0px 0px 0px #eee;
      box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
      -moz-box-shadow: 0px 0px 10px 5px #eee;        
      box-shadow: 0px 0px 10px 5px #eee;        
  }

  70% {
      -moz-box-shadow: 0px 0px 10px 5px #09c6ab;        
      box-shadow: 0px 0px 10px 5px #09c6ab;        
  }

  100% {
      -moz-box-shadow: 0px 0px 0px 0px #09c6ab;        
      box-shadow: 0px 0px 0px 0px #09c6ab;        
  }
}

@-ms-keyframes testim-scale {
  0% {
      -ms-box-shadow: 0px 0px 0px 0px #eee;
      box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
      -ms-box-shadow: 0px 0px 10px 5px #eee;        
      box-shadow: 0px 0px 10px 5px #eee;        
  }

  70% {
      -ms-box-shadow: 0px 0px 10px 5px #09c6ab;        
      box-shadow: 0px 0px 10px 5px #09c6ab;        
  }

  100% {
      -ms-box-shadow: 0px 0px 0px 0px #09c6ab;        
      box-shadow: 0px 0px 0px 0px #09c6ab;        
  }
}

@-o-keyframes testim-scale {
  0% {
      -o-box-shadow: 0px 0px 0px 0px #eee;
      box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
      -o-box-shadow: 0px 0px 10px 5px #eee;        
      box-shadow: 0px 0px 10px 5px #eee;        
  }

  70% {
      -o-box-shadow: 0px 0px 10px 5px #09c6ab;        
      box-shadow: 0px 0px 10px 5px #09c6ab;        
  }

  100% {
      -o-box-shadow: 0px 0px 0px 0px #09c6ab;        
      box-shadow: 0px 0px 0px 0px #09c6ab;        
  }
}

@keyframes testim-scale {
  0% {
      box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
      box-shadow: 0px 0px 10px 5px #eee;        
  }

  70% {
      box-shadow: 0px 0px 10px 5px #09c6ab;        
  }

  100% {
      box-shadow: 0px 0px 0px 0px #09c6ab;        
  }
}

@-webkit-keyframes testim-content-in {
  from {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
  }
  
  to {
      opacity: 1;
      -webkit-transform: translateY(0);        
      transform: translateY(0);        
  }
}

@-moz-keyframes testim-content-in {
  from {
      opacity: 0;
      -moz-transform: translateY(100%);
      transform: translateY(100%);
  }
  
  to {
      opacity: 1;
      -moz-transform: translateY(0);        
      transform: translateY(0);        
  }
}

@-ms-keyframes testim-content-in {
  from {
      opacity: 0;
      -ms-transform: translateY(100%);
      transform: translateY(100%);
  }
  
  to {
      opacity: 1;
      -ms-transform: translateY(0);        
      transform: translateY(0);        
  }
}

@-o-keyframes testim-content-in {
  from {
      opacity: 0;
      -o-transform: translateY(100%);
      transform: translateY(100%);
  }
  
  to {
      opacity: 1;
      -o-transform: translateY(0);        
      transform: translateY(0);        
  }
}

@keyframes testim-content-in {
  from {
      opacity: 0;
      transform: translateY(100%);
  }
  
  to {
      opacity: 1;
      transform: translateY(0);        
  }
}

@-webkit-keyframes testim-content-out {
  from {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }
  
  to {
      opacity: 0;
      -webkit-transform: translateY(-100%);        
      transform: translateY(-100%);        
  }
}

@-moz-keyframes testim-content-out {
  from {
      opacity: 1;
      -moz-transform: translateY(0);
      transform: translateY(0);
  }
  
  to {
      opacity: 0;
      -moz-transform: translateY(-100%);        
      transform: translateY(-100%);        
  }
}

@-ms-keyframes testim-content-out {
  from {
      opacity: 1;
      -ms-transform: translateY(0);
      transform: translateY(0);
  }
  
  to {
      opacity: 0;
      -ms-transform: translateY(-100%);        
      transform: translateY(-100%);        
  }
}

@-o-keyframes testim-content-out {
  from {
      opacity: 1;
      -o-transform: translateY(0);
      transform: translateY(0);
  }
  
  to {
      opacity: 0;
      transform: translateY(-100%);        
      transform: translateY(-100%);        
  }
}

@keyframes testim-content-out {
  from {
      opacity: 1;
      transform: translateY(0);
  }
  
  to {
      opacity: 0;
      transform: translateY(-100%);        
  }
}

@-webkit-keyframes testim-show {
  from {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  
  to {
      opacity: 1;
      -webkit-transform: scale(1);       
      transform: scale(1);       
  }
}

@-moz-keyframes testim-show {
  from {
      opacity: 0;
      -moz-transform: scale(0);
      transform: scale(0);
  }
  
  to {
      opacity: 1;
      -moz-transform: scale(1);       
      transform: scale(1);       
  }
}

@-ms-keyframes testim-show {
  from {
      opacity: 0;
      -ms-transform: scale(0);
      transform: scale(0);
  }
  
  to {
      opacity: 1;
      -ms-transform: scale(1);       
      transform: scale(1);       
  }
}

@-o-keyframes testim-show {
  from {
      opacity: 0;
      -o-transform: scale(0);
      transform: scale(0);
  }
  
  to {
      opacity: 1;
      -o-transform: scale(1);       
      transform: scale(1);       
  }
}

@keyframes testim-show {
  from {
      opacity: 0;
      transform: scale(0);
  }
  
  to {
      opacity: 1;
      transform: scale(1);       
  }
}

@-webkit-keyframes testim-hide {
  from {
      opacity: 1;
      -webkit-transform: scale(1);       
      transform: scale(1);       
  }
  
  to {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
      opacity: 1;
      -moz-transform: scale(1);       
      transform: scale(1);       
  }
  
  to {
      opacity: 0;
      -moz-transform: scale(0);
      transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
      opacity: 1;
      -ms-transform: scale(1);       
      transform: scale(1);       
  }
  
  to {
      opacity: 0;
      -ms-transform: scale(0);
      transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
      opacity: 1;
      -o-transform: scale(1);       
      transform: scale(1);       
  }
  
  to {
      opacity: 0;
      -o-transform: scale(0);
      transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
      opacity: 1;
      transform: scale(1);       
  }
  
  to {
      opacity: 0;
      transform: scale(0);
  }
}

.testimonials__h1 {
  padding: 50px 0 0;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.btns-testimonials {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.btn-testimonial {
  line-height: 50px !important;
  width: 280px;
  height: 50px;
  background-color: #09c6ab;
  color: #fff;
  padding: 0 !important;
  transition: transform 0.2s;
}

.btn-testimonial:hover,
.btn-testimonial:active,
.btn-testimonial:focus {
  background-color: #0adfbf;
  color: #fff !important;
}

.size {
  margin-right: 10px;
  font-size: 15px !important;
  line-height: 50px
}

@media all and (max-width: 300px) {
body {
  font-size: 14px;
}
}

@media screen and (max-width: 700px) {
  .testimonials-container {
    height: 650px;
  }
  .testimonials__h1 {
    padding: 20px 0 0;
  }
  
  .btns-testimonials {
    flex-direction: column;
    align-items: center;
  }
}

@media all and (max-width: 500px) {
.testim .arrow {
  font-size: 1.5em;
}

.testim .cont div p {
  line-height: 25px;
}

}

@media all and (max-width: 400px) {
  .testimonials__h1 {
    font-size: 22px;
  }

  .testim .cont div p {
    font-size: 14px;
    line-height: 18px;
  }
  
}
